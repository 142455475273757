import * as React from 'react';
import styled from 'styled-components';

import Layout from '../layout/layout';
import SEO from '../components/SEO';

const NotFoundWrap = styled.section`
  max-width: 50vw;
  margin: 0 auto;
  padding: 5rem 0;

  > h1 {
    font-size: 10vmin;
  }

  > p {
    font-size: 3vmin;
  }
`;

const NotFoundPage = () => (
  <Layout>
    <SEO
      title="Ohno - Page not found"
      description="The page you were looking for is not found in this website."
    />
    <NotFoundWrap>
      <h1>Broken Link</h1>
      <p>
        You just clicked on a link that we couldn&#39;t locate in this website.
      </p>
    </NotFoundWrap>
  </Layout>
);

export default NotFoundPage;
